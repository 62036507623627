<template>
  <div id="Manage"
       v-title
       data-title="旅游管理">
    <!-- 产品管理   旅游管理 -->

    <!-- Dialog 对话框 -->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               width="65%">
      <CommonTabForm :form="openForm"
                     :activeTabName.sync="activeTabName"
                     :tabLabel.sync="tabFormLabel"
                     :tableData="touristDatePrice"
                     @clickFormToTable="calculateDate"
                     @blurDate='blurDate'
                     @clickFormModify="modifyDate"
                     @getModifychecked="getChecked"
                     @handleRemove="handleRemove"
                     @getScreendate='getScreen'
                     @clickDeleteData="clickDeleteDatePrice"
                     @tabHandleClick="swichTab">
        <!-- tab第三页 -->
        <div slot="fourth"
             class="fourthTab-From">
          <el-button id="fourthTab-button"
                     type="primary"
                     size="small"
                     @click="clickAddTripData()">添加行程天数</el-button>
          <CommonTable style="height:300px;"
                       :tableData="touristScheduleData"
                       :tableLabel="tabFormLabel[3].tableLabel"
                       :tableConfig="tabFormLabel[3].tableConfig"
                       @changeSwitch="changeScheduleSwitch"
                       @clickEditData="clickEditScheduleData"
                       @clickDeleteData="clickDeleteScheduleData"></CommonTable>

          <div class="fourthTab-From"
               style="background: #f8f7f7;
                      padding: 12px 8px;">
            <p id="fourthTab-From-p">{{fourthTab_title}}</p>
            <CommonForm :form="openFormTrip"
                        :formLabel="tabFormLabel[3].formLabel"
                        @clickInputBtn="clickAddScenic"
                        style="padding-right:60%;">
              <div class="box">
                <el-card class="box-card"
                         shadow="hover"
                         v-for=" item in openFormTrip['scenic_spots']"
                         :key="item.index">
                  <div slot="header"
                       class="clearfix">
                    <span>景点{{item.index}}</span>
                    <el-button style="float: right;"
                               @click="clickDeleteCard(item)"
                               type="danger">删除</el-button>
                  </div>
                  <div class="text item">
                    <CommonForm :form="item"
                                :formLabel="tabFormLabel[3].formLabel[10].formLabel"
                                :labelWidth="'100px'"
                                :style="' position: relative;'">
                    </CommonForm>
                  </div>
                </el-card>
              </div>
              <el-button size="small"
                         @click="clickResetScheduleFrom()">重置</el-button>
              <el-button type="primary"
                         size="small"
                         @click="clickSavaScheduleData(openFormTrip,AddOrEditType)">保存</el-button>
            </CommonForm>
          </div>
        </div>

      </CommonTabForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData()">{{dialogBtnName}}</el-button>
      </div>
    </el-dialog>

    <!-- Dialog 对话框 审核表单-->
    <el-dialog :title="dialogFonfigAudit.auditName"
               class="dialogAudit"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigAudit.isShowDialogVisible">
      <CommonForm :form="auditForm"
                  class="dialogAuditForm"
                  :formLabel="auditFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="danger"
                   @click="clickSubmitAuditData(2, auditForm)">驳 回</el-button>
        <el-button type="success"
                   @click="clickSubmitAuditData(3, auditForm)">通 过</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">发布线路</el-button>
          <CommonForm :form="searchForm"
                      :formLabel="searchformLabel"
                      ref="select"
                      class="filtrateInput">
            <el-button type="primary"
                       @click="searchKeyword(searchForm)">搜 索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text"
                       @click="resetFilter(searchForm)">重置筛选条件</el-button>
          </CommonForm>
        </div>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTabForm :tableData="tableData"
                       :activeTableName.sync="activeTableName"
                       :tabLabel="tabTableLabel"
                       @tabHandleClick="swichTable"
                       @getPage="getPage"
                       @getNumArticle="getNumArticle"
                       @changeSwitch="changeTourismSwitch"
                       @clickEditData="clickEditTourismData"
                       @clickDeleteData="clickDeleteTourismData"
                       @clickWarningData="clickAuditTourismData">
        </CommonTabForm>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>

import * as api from 'api/productManage.js'
import { getTouristArea, getTouristType, getAreaProvinceCity } from 'api/basisDate.js'
import { getScenicList } from 'api/basis.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
import CommonTabForm from 'components/CommonTab_Form.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain,
    CommonTabForm
  },
  data () {
    return {
      getchecked: [], // 存放复选框
      formdIndex: 0, // 日期价格中日期不同时有不同的index
      resultArr: '', // 存放日期价格中不同的result
      dateArray: '', // 存放日期数据
      imgIndex: [], // 获取删除后的图片
      searchData: '', // 存放搜索的数据，分页时使用
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增线路',
        editName: '编辑线路',
        isShowDialogVisible: false
      },
      // Tab 表格列表  储备列表、运营列表
      tabTableLabel: [
        {
          name: 'first',
          label: '储备列表',
          type: 'table',
          tableLabel: [
            {
              prop: 'id',
              label: '编号'
            },
            {
              prop: 'product_pic',
              label: '封面图',
              type: 'image'
            },
            {
              prop: 'name',
              label: '线路名称'
            },
            {
              prop: 'cost',
              label: '供货价'
            },
            {
              prop: 'vip_price',
              label: '会员价'
            },
            {
              prop: 'supplier_id',
              label: '供应商简称'
            },
            {
              prop: 'update_time',
              label: '创建时间'
            },
            {
              prop: 'status',
              label: '状态',
              type: 'State'
            },
            {
              prop: 'admin_id',
              label: '审核员'
            },
            {
              prop: 'operation',
              label: '操作',
              type: 'operation',
              width: '150px',
              children: [
                {
                  content: '审核',
                  type: 'warning'
                },
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            // 显示表格top按钮
            isShowTopBtn: true
          }
        },
        {
          name: 'second',
          label: '运营列表',
          type: 'table',
          tableLabel: [
            {
              prop: 'id',
              label: '编号'
            },
            {
              prop: 'name',
              label: '线路名称'
            },
            {
              prop: 'product_pic',
              label: '封面图',
              type: 'image'
            },
            {
              prop: 'cost',
              label: '供货价'
            },
            {
              prop: 'vip_price',
              label: '会员价'
            },
            {
              prop: 'supplier_id',
              label: '供应商简称'
            },
            {
              prop: 'update_time',
              label: '创建时间'
            },
            {
              prop: 'admin_id',
              label: '审核员'
            },
            {
              prop: 'status',
              label: '下/上架',
              type: 'state'
            },
            {
              prop: 'operation',
              label: '操作',
              type: 'operation',
              width: '150px',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            // 显示表格top按钮
            isShowTopBtn: true
          }
        }
      ],

      // form表单 基础数据
      openForm: {
        // 基础信息
        id: '',
        name: '',
        describe: '',
        code_no: '',
        notice_mobile: '',
        supplier_id: 0,
        category_id: [],
        product_pic: '',
        images: [],
        discount: '',
        sales_price: '',
        vip_price: '',
        cost: '',
        settlement_price: '',
        kid_sales_price: '',
        kid_vip_price: '',
        kid_cost: '',
        kid_settlement_price: '',
        is_hot: 0,
        is_red: 0,
        is_coupon: 0,
        is_integral: 0,
        is_stock: 0,
        is_kid: 0,
        vrurl: '',
        day_num: '',
        travel_mode: '',
        departure: [],
        destination: [],
        transport: '',
        group_mode: '',
        month: '',
        station: [],
        sales_num: '',
        sn: '',

        // 推荐理由
        recommend: '',
        // 费用说明
        expense_explanation: '',
        // 产品特色
        feature_description: '',
        // 订购须知
        buy_notice: ''
      },
      openFormTrip: {
        // 旅游行程信息
        product_id: '', // 线路id
        id: '', // 日程id
        title: '',
        outline: '',
        day: '',
        meal: '',
        meal_explain: '',
        meal_img: [],
        hotel_id: [], // 关联酒店ID 组件待完善
        dwell: '',
        dwell_explain: '',
        dwell_img: [],
        Existingname: '', // 已有景点
        Existingname1: '', // 自主添加景点
        scenic_spots: [
          {
            name: '',
            explain: '景点描述 ',
            img: [],

            note: '注意事项 ',
            logo_type: 1
          }
        ],
        attraction_id: [], // 关联景点元素ID 组件待完善
        note: '',
        num: 1
      },
      tabFormLabel: [// form表单 组件
        {
          name: 'first',
          label: '基本信息',
          type: 'form',
          children: [
            {
              model: 'name',
              label: '线路名称',
              width: '420px',
              required: true
            },
            {
              model: 'describe',
              label: '线路简介',
              type: 'textarea',
              width: '500px',
              required: true
            },
            {
              model: 'code_no',
              label: '线路编号',
              width: '380px',
              required: true
            },
            {
              model: 'notice_mobile',
              label: '短信通知手机',
              width: '380px'
            },
            {
              model: 'supplier_id',
              label: '所属供应商',
              type: 'select',
              width: '340px',
              options: [
                {
                  value: 0,
                  label: '观视界自营'
                }
              ],
              required: true
            },
            {
              model: 'category_id',
              label: '所属分类',
              type: 'treeSelect',
              options: [],
              defaultProps: {
                label: 'label',
                children: 'children'
              },
              collapseTags: false,
              nodeKey: 'value',
              required: true
            },
            {
              model: 'vrurl',
              label: 'VR链接'
              // required: true
            },
            {
              model: 'day_num',
              label: '行程天数',
              required: true,
              tip: '天'
            },
            {
              model: 'travel_mode',
              label: '旅行模式',
              type: 'select',
              options: [
                {
                  value: 0,
                  label: '自由行'
                },
                {
                  value: 1,
                  label: '跟团游'
                }
              ],
              required: true
            },
            {
              model: 'departure',
              label: '出发城市',
              type: 'cascader',
              props: {
                multiple: true
              },
              options: [],
              size: 'small',
              placeholder: '请选择出发城市',
              required: true

            },
            {
              model: 'destination',
              label: '目的城市',
              type: 'cascader',
              props: {
                multiple: true
              },
              options: [],
              size: 'small',
              placeholder: '请选择目的城市',
              required: true
            },
            {
              model: 'transport',
              label: '出行方式',
              required: true
            },
            {
              model: 'group_mode',
              label: '发团方式',
              required: true
            },
            {
              model: 'station',
              label: '上车地点',
              type: 'tab_create'
              // required: true
            },
            {
              model: 'month',
              label: '适合出游月份',
              required: true
            },
            {
              model: 'sales_num',
              label: '销量',
              required: true
            },
            {
              model: 'sn',
              label: '排序',
              required: true
            },
            {
              model: 'is_hot',
              label: '热门',
              type: 'switch'

            },
            {
              model: 'is_red',
              label: '推荐',
              type: 'switch'

            },
            {
              model: 'is_coupon',
              label: '可用券',
              type: 'switch'

            },
            {
              model: 'is_integral',
              label: '赠送积分',
              type: 'switch'

            },
            {
              model: 'is_stock',
              label: '库存',
              type: 'switch'

            },
            {
              model: 'is_kid',
              label: '儿童',
              type: 'switch'
            },
            {
              model: 'sales_price',
              label: '销售价',
              width: '320px',
              tip: '￥',
              required: true
            },
            {
              model: 'vip_price',
              label: '会员价',
              width: '320px',
              tip: '￥',
              required: true
            },
            {
              model: 'cost',
              label: '供货价',
              width: '320px',
              tip: '￥',
              required: true
            },
            {
              model: 'settlement_price',
              label: '结算价',
              width: '320px',
              tip: '￥',
              required: true
            },
            {
              model: 'kid_sales_price',
              label: '儿童销售价',
              width: '320px',
              tip: '￥',
              required: true
            },
            {
              model: 'kid_vip_price',
              label: '儿童会员价',
              width: '320px',
              tip: '￥',
              required: true
            },
            {
              model: 'kid_cost',
              label: '儿童供货价',
              width: '320px',
              tip: '￥',
              required: true
            },
            {
              model: 'kid_settlement_price',
              label: '儿童结算价',
              width: '320px',
              tip: '￥',
              required: true
            },
            {
              model: 'discount',
              label: '折扣',
              width: '320px',
              tip: '折',
              required: true
            },
            {
              model: 'product_pic',
              label: '封面图',
              type: 'upload',
              imgHeight: '1280',
              imgWidth: '808',
              required: true
            },
            {
              model: 'images',
              label: '轮播图',
              type: 'uploadList',
              maxSize: 2048,
              imgHeight: '1280',
              imgWidth: '808',
              required: true
            }
          ]
        },
        {
          name: 'second',
          label: '推荐理由',
          type: 'wangedito',
          model: 'recommend',
          wangeDitorConfig: {
            height: 300,
            focus: true,
            placeholder: '请输入推荐理由...'
          }
        },
        {
          name: 'third',
          label: '产品特色',
          type: 'wangedito',
          model: 'feature_description',
          wangeDitorConfig: {
            height: 300,
            focus: true,
            placeholder: '请输入产品特色...'
          }
        },
        {
          name: 'fourth',
          label: '行程天数',
          type: 'slot',
          tableLabel: [
            {
              prop: 'day',
              label: '天数'
            },
            {
              prop: 'title',
              label: '游玩内容'
            },
            {
              prop: 'meal',
              label: '餐饮'
            },
            {
              prop: 'dwell',
              label: '住宿'
            },
            {
              prop: 'status',
              label: '启/禁用',
              type: 'state'
            },
            {
              prop: 'operation',
              label: '操作',
              type: 'operation',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: false,
            // 加载状态
            loading: false,
            // 显示 选择多行数据
            selection: false,
            // 显示表格top按钮
            isShowTopBtn: false
          },
          formLabel: [
            {
              model: 'day',
              label: '第几天',
              width: '240px',
              required: true
            },
            {
              model: 'title',
              label: '行程标题',
              width: '360px',
              required: true
            },
            {
              model: 'outline',
              label: '行程描述',
              width: '360px',
              type: 'textarea',
              required: true
            },
            {
              model: 'meal',
              label: '用 餐',
              width: '320px'
            },
            {
              model: 'meal_explain',
              label: '用餐说明',
              width: '360px',
              type: 'textarea'
            },
            {
              model: 'meal_img',
              label: '用餐图片',
              imgHeight: '1280',
              imgWidth: '600',
              maxSize: 2048,
              type: 'uploadList'
            },
            {
              model: 'dwell',
              label: '住 宿',
              width: '320px'
            },
            {
              model: 'dwell_explain',
              label: '住宿说明',
              width: '360px',
              type: 'textarea'
            },
            {
              model: 'dwell_img',
              label: '住宿图片',
              imgHeight: '1280',
              imgWidth: '600',
              maxSize: 2048,
              type: 'uploadList'
            },
            {
              model: 'note',
              label: '注意事项',
              width: '420px',
              type: 'textarea'
            },
            {
              model: 'num',
              label: '景点数',
              width: '240px',
              type: 'inputButton',
              name: '+',
              formLabel: [
                {
                  model: 'Existingname',
                  label: '已有景点',
                  width: '240px',
                  type: 'treeSelect',
                  options: [],
                  defaultProps: {
                    label: 'label',
                    children: 'children'
                  },
                  collapseTags: false,
                  nodeKey: 'value'
                },
                {
                  model: 'Existingname1',
                  label: '自主添加',
                  width2: '275px',
                  type: 'inputClues',
                  clues: '若景点信息未上传，请手动输入'
                },
                {
                  model: 'explain',
                  label: '描述',
                  width: '260px',
                  type: 'textarea'
                },
                {
                  model: 'img',
                  label: '景点图片',
                  imgHeight: '1280',
                  imgWidth: '600',
                  maxSize: 2048,
                  type: 'uploadList'
                },
                {
                  model: 'note',
                  label: '注意事项',
                  width: '260px',
                  type: 'textarea'
                }
              ]
            }
          ]
        },

        {
          name: 'fifth',
          label: '费用说明',
          type: 'wangedito',
          model: 'expense_explanation',
          wangeDitorConfig: {
            height: 300,
            focus: true,
            placeholder: '请输入费用说明...'
          }
        },

        {
          name: 'sixth',
          label: '预定须知',
          type: 'wangedito',
          model: 'buy_notice',
          wangeDitorConfig: {
            height: 300,
            focus: true,
            placeholder: '请输入预定须知...'
          }
        },
        {
          name: 'seventh',
          label: '日期价格',
          type: 'table',
          form: 'price_data',
          formBtnName: '批量添加',
          formModify: '已选修改',
          // tab-table表单数据
          formTable: {
            id: '',
            date: '',
            discount: '',
            sales_price: '',
            vip_price: '',
            kid_sales_price: '',
            kid_vip_price: '',
            kid_cost: '',
            kid_settlement_price: '',
            cost: '',
            settlement_price: '',
            integral_price: '',
            stock_num: '',
            frequency: ''
          },
          formLabel: [
            {
              model: 'date',
              label: '日期范围',
              type: 'datePicker',
              // width: '340px',
              expireTimeOption: {
                disabledDate (date) {
                  // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                  return date.getTime() < Date.now() - 24 * 60 * 60 * 1000
                }
              }
            },
            {
              model: 'day',
              type: 'week',
              options: [
                { id: 0, dayweek: '星期日' },
                { id: 1, dayweek: '星期一' },
                { id: 2, dayweek: '星期二' },
                { id: 3, dayweek: '星期三' },
                { id: 4, dayweek: '星期四' },
                { id: 5, dayweek: '星期五' },
                { id: 6, dayweek: '星期六' }
              ]
            },
            {
              model: 'discount',
              label: '会员折扣',
              width: '450px',
              tip: '折'
            },
            {
              model: 'sales_price',
              label: '销售价',
              width: '450px',
              tip: '￥'
            },
            {
              model: 'vip_price',
              label: '会员价',
              width: '450px',
              tip: '￥'
            },
            {
              model: 'kid_sales_price',
              label: '儿童销售价',
              width: '450px',
              tip: '￥'
            },
            {
              model: 'kid_vip_price',
              label: '儿童会员价',
              width: '450px',
              tip: '￥'
            },
            {
              model: 'cost',
              label: '供货价',
              width: '450px',
              tip: '￥'
            },
            {
              model: 'settlement_price',
              label: '结算价',
              width: '450px',
              tip: '￥'
            },

            {
              model: 'kid_cost',
              label: '儿童供货价',
              width: '450px',
              tip: '￥'
            },
            {
              model: 'kid_settlement_price',
              label: '儿童结算价',
              width: '450px',
              tip: '￥'
            },
            {
              model: 'integral_price',
              label: '积分抵用金额',
              width: '450px'
            },
            {
              model: 'stock_num',
              label: '库存',
              width: '450px'
            }

          ],
          tableLabel: [
            {
              prop: 'date',
              label: '出团日期',
              type: 'datePicker'
            },
            {
              prop: 'sales_price',
              label: '销售价',
              type: 'input'
            },
            {
              prop: 'vip_price',
              label: '会员价',
              type: 'input'
            },
            {
              prop: 'cost',
              label: '供货价',
              type: 'input'
            },
            {
              prop: 'settlement_price',
              label: '结算价',
              type: 'input'
            },
            {
              prop: 'kid_vip_price',
              label: '儿童会员价',
              type: 'input'
            },
            {
              prop: 'kid_cost',
              label: '儿童代理价',
              type: 'input'
            },
            {
              prop: 'kid_settlement_price',
              label: '儿童结算价',
              type: 'input'
            },
            {
              prop: 'discount',
              label: '会员折扣',
              type: 'input'
            },
            {
              prop: 'integral_price',
              label: '积分抵用金额',
              type: 'input'
            },
            {
              prop: 'stock_num',
              label: '库存',
              type: 'input'
            },
            {
              prop: 'operation',
              label: '操作',
              type: 'operation',
              children: [
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: false,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: false,
            // 显示 选择多行数据
            selection: false,
            // row key
            rowKey: 'date',
            // 显示表格top按钮
            isShowTopBtn: false
          }
        }
      ],

      // form表单 搜索数据
      searchForm: {
        touristId: '',
        touristName: '',
        touristStatus: '',
        touristGroup: '',
        supplier: '',
        is_red: '',
        is_hot: '',
        is_coupon: '',
        is_integral: '',
        is_stock: ''
      },
      searchformLabel: [
        // {
        //   model: 'touristId',
        //   label: '线路编号',
        //   width: '340px'
        // },
        {
          model: 'touristName',
          label: '线路名称',
          width: '304px'
        },
        {
          model: 'supplier',
          label: '供应商名称',
          width: '300px',
          type: 'select',
          options: [
            { value: 0, label: '观视界自营' }
          ]
        },
        {
          model: 'touristStatus',
          label: '线路状态',
          type: 'select',
          width: '230px',
          options: [
            { value: 0, label: '申请' },
            // { value: 1, label: '正常' },
            { value: 2, label: '驳回' }
            // { value: 3, label: '下架' },
          ]
        },
        {
          model: 'touristGroup',
          label: '旅行模式',
          type: 'select',
          width: '220px',
          options: [
            { value: 0, label: '自由行' },
            { value: 1, label: '跟团游' }
          ]
        }
        // {
        //   model: 'is_red',
        //   label: '推荐',
        //   type: 'select',
        //   width: '220px',
        //   options: [
        //     { value: 0, label: '否' },
        //     { value: 1, label: '是' }
        //   ]
        // },
        // {
        //   model: 'is_hot',
        //   label: '热门',
        //   type: 'select',
        //   width: '220px',
        //   options: [
        //     { value: 0, label: '否' },
        //     { value: 1, label: '是' }
        //   ]
        // },
        // {
        //   model: 'is_coupon',
        //   label: '用劵',
        //   type: 'select',
        //   width: '220px',
        //   options: [
        //     { value: 0, label: '否' },
        //     { value: 1, label: '是' }
        //   ]
        // },
        // {
        //   model: 'is_integral',
        //   label: '赠送积分',
        //   type: 'select',
        //   width: '220px',
        //   options: [
        //     { value: 0, label: '否' },
        //     { value: 1, label: '是' }
        //   ]
        // },
        // {
        //   model: 'is_stock',
        //   label: '库存',
        //   type: 'select',
        //   width: '220px',
        //   options: [
        //     { value: 0, label: '否' },
        //     { value: 1, label: '是' }
        //   ]
        // },
      ],
      // table 表格 基础数据
      tableData: [],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        NumArticle: 10, // 每页的最大条数
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false
      },
      // 对话框 Dialog 审核酒店民宿
      dialogFonfigAudit: {
        auditName: '待审核',
        isShowDialogVisible: false
      },
      // form表单 审核
      auditForm: {
        id: '',
        name: '',
        image: '',
        status: '',
        reason: ''
      },
      auditFormLabel: [
        {
          model: 'id',
          label: '线路编号',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'name',
          label: '线路名称',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'product_pic',
          label: '封面图',
          type: 'image'
        },
        {
          model: 'reason',
          label: '审核理由',
          type: 'textarea',
          width: '600px',
          required: true
        }

      ],
      // 线路-全部-数据
      tourismAllDataObj: {},
      // 线路-格式处理-列表数据
      tourismFormatDealListData: [],
      // catgory_id最大值
      MaxId: '',
      // 储蓄列表 或 正式运营列表 Name
      activeTableName: 'first',
      // Dialog新增/编辑 Name
      activeTabName: 'first',
      // 提交保存信息的 按钮名称
      dialogBtnName: '下一步',
      // 线路日期价格 列表
      touristDatePrice: [],
      // 线路日程价格 列表
      touristScheduleData: [],
      // 旅游分类 选项
      optionsTouristType: [],
      // 旅游区域 出发选项
      optionsDeparture: [],
      // 旅游区域 目的选项
      optionsDestination: [],
      // 行程天数下的旅游景点
      optionsScenic: [],
      // Tab第三页 title
      fourthTab_title: '新增行程',
      // Tab第三页 新增/编辑
      AddOrEditType: 'add'
    }
  },
  mounted () {
    this.getTourismProductToAudit()
    console.log(this.tabFormLabel[0].children[8])
  },
  watch: {
    tourismAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.tourismAllDataObj.list,
        {},
        0
      )
    },
    optionsScenic: function () {
      this.tabFormLabel[3].formLabel[10].formLabel[0].options = this.dataFormatDeal(this.optionsScenic, {
        id: 'value',
        name: 'label',
        child: 'children'
      }, 1)
    },
    optionsTouristType: function () {
      this.tabFormLabel[0].children[5].options = this.dataFormatDeal(this.optionsTouristType, {
        id: 'value',
        category_name: 'label',
        child: 'children'
      }, 1)
    },
    optionsDeparture: function () {
      this.tabFormLabel[0].children[9].options = this.dataFormatDeal(this.optionsDeparture, {
        id: 'value',
        name: 'label',
        child: 'children'
      }, 1)
    },
    optionsDestination: function () {
      const allTouriseArea = this.dataFormatDeal(this.optionsDestination, {
        id: 'value',
        name: 'label',
        child: 'children'
      }, 1)
      this.tabFormLabel[0].children[10].options = allTouriseArea
    },
    activeTabName: function () {
      if (this.activeTabName == 'seventh') {
        this.dialogBtnName = '保 存'
      } else {
        this.dialogBtnName = '下一步'
      }
    },
    'openFormTrip.scenic_spots' () {
      this.openFormTrip.num = this.openFormTrip.scenic_spots.length
    },
    'dialogFonfig.isShowDialogVisible' (newValue, oldValue) {
      if (newValue == false) {
        // 关闭Dialog ,下次打开返回第一页
        this.activeTabName = 'first'
        // 清空表单
        this.clearFromData()
      }
    },
    AddOrEditType (newData, oldData) {
      if (newData == 'add') {
        this.fourthTab_title = '新增行程'
        const product_id = this.openFormTrip.product_id
        this.openFormTrip = this.$options.data().openFormTrip
        this.openFormTrip.product_id = product_id
      }
      if (newData == 'edit') {
        this.fourthTab_title = '编辑行程'
      }
    }
  },

  methods: {
    // 查询操作
    searchKeyword (searchForm) {
      const data = {
        search: searchForm.touristName,
        status: searchForm.touristStatus,
        travel_mode: searchForm.touristGroup,
        supplier_id: searchForm.supplier
      }
      this.searchData = data
      if (this.activeTableName == 'first') {
        this.getTourismProductToAudit(data)
      } else if (this.activeTableName == 'second') {
        this.getTourismProductToOperation(data)
      }
    },
    // 重置筛选条件操作
    resetFilter (searchForm) {
      searchForm.touristName = ''
      searchForm.touristGroup = ' '
      searchForm.touristStatus = ' '
      searchForm.supplier = ' '
      this.searchData = ''
      console.log(this)
      if (this.activeTableName == 'second') {
        this.getTourismProductToOperation()
      } else if (this.activeTableName == 'first') {
        this.getTourismProductToAudit()
      }
    },
    // 获取下面分页的页数
    getPage () {
      console.log(this.tabTableLabel[0].tableConfig.page)
      if (this.activeTableName == 'first') {
        if (this.searchData) {
          this.searchData.page = this.tabTableLabel[0].tableConfig.page
          this.searchData.page_rows = this.tabTableLabel[0].tableConfig.NumArticle
          this.getTourismProductToAudit(this.searchData)
        } else {
          this.getTourismProductToAudit()
        }
      } else if (this.activeTableName == 'second') {
        if (this.searchData) {
          this.searchData.page = this.tabTableLabel[1].tableConfig.page
          this.searchData.page_rows = this.tabTableLabel[1].tableConfig.NumArticle
          this.getTourismProductToOperation(this.searchData)
        } else {
          this.getTourismProductToOperation()
        }
      }
    },

    // 获取下面分页的页数的最大条数
    getNumArticle (NumArticle) {
      this.tabTableLabel[0].tableConfig.NumArticle = NumArticle
      this.tabTableLabel[1].tableConfig.NumArticle = NumArticle
      console.log(this.tabTableLabel[0].tableConfig.NumArticle)
      if (this.activeTableName == 'first') {
        if (this.searchData) {
          this.searchData.page = this.tabTableLabel[0].tableConfig.page
          this.searchData.page_rows = this.tabTableLabel[0].tableConfig.NumArticle
          console.log(this.searchData)
          this.getTourismProductToAudit(this.searchData)
        } else {
          this.getTourismProductToAudit()
        }
      } else if (this.activeTableName == 'second') {
        if (this.searchData) {
          this.searchData.page = this.tabTableLabel[1].tableConfig.page
          this.searchData.page_rows = this.tabTableLabel[1].tableConfig.NumArticle
          this.getTourismProductToOperation(this.searchData)
        } else {
          this.getTourismProductToOperation()
        }
      }
    },
    // 待审核 线路
    getTourismProductToAudit (data) {
      if (data) {
        api.getTourismProductToAudit(data).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.tourismAllDataObj = DATA.data
            this.tabTableLabel[0].tableConfig.total = DATA.data.total
            this.tabTableLabel[0].tableConfig.loading = false
            // console.log(DATA)
          }
        })
      } else {
        api.getTourismProductToAudit({
          page: this.tabTableLabel[0].tableConfig.page,
          page_rows: this.tabTableLabel[0].tableConfig.NumArticle
        }).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.tourismAllDataObj = DATA.data

            this.tabTableLabel[0].tableConfig.total = DATA.data.total
            this.tabTableLabel[0].tableConfig.loading = false
          }
          console.log(DATA)
        })
      }
    },

    // 正式运营
    getTourismProductToOperation (data) {
      if (data) {
        api.getTourismProductToOperation(data).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.tourismAllDataObj = DATA.data
            this.tabTableLabel[1].tableConfig.total = DATA.data.total
            this.tabTableLabel[1].tableConfig.loading = false
            // console.log(DATA)
          }
        })
      } else {
        api.getTourismProductToOperation({
          page: this.tabTableLabel[1].tableConfig.page,
          page_rows: this.tabTableLabel[1].tableConfig.NumArticle
        }).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.tourismAllDataObj = DATA.data

            this.tabTableLabel[1].tableConfig.total = DATA.data.total
            this.tabTableLabel[1].tableConfig.loading = false
          }
          console.log(DATA)
        })
      }
    },

    // 获取线路 详细信息
    getTourismProductShow (data) {
      return new Promise(resolve => {
        api.getTourismProductShow(data).then(res => {
          resolve(res.data)
        })
      })
    },
    // 新增、编辑 线路
    metTourismProductEdit (met, data) {
      return new Promise(resolve => {
        api.metTourismProductEdit(met, data).then(res => {
          resolve(res.data)
        })
      })
    },

    // 保存-推荐理由
    setTourismProductRecommend (data) {
      return new Promise(resolve => {
        api.setTourismProductRecommend(data).then(res => {
          resolve(res.data)
        })
      })
    },

    // 保存-费用说明
    setTourismProductExpense (data) {
      return new Promise(resolve => {
        api.setTourismProductExpense(data).then(res => {
          resolve(res.data)
        })
      })
    },

    // 保存-产品特色
    setTourismProductFeature (data) {
      return new Promise(resolve => {
        api.setTourismProductFeature(data).then(res => {
          resolve(res.data)
        })
      })
    },

    // 保存-订购须知
    setTourismProductBuyNotice (data) {
      return new Promise(resolve => {
        api.setTourismProductBuyNotice(data).then(res => {
          resolve(res.data)
        })
      })
    },

    // 旅游线路-变更上/下架状态
    ChangeTourismProductStatus (data) {
      return new Promise(resolve => {
        api.ChangeTourismProductStatus(data).then(res => {
          resolve(res)
        })
      })
    },

    // 旅游线路 日程列表
    getTourismProductSchedule (data) {
      api.getTourismProductSchedule(data).then(res => {
        const DATA = res.data
        if (DATA.code == 200) {
          this.touristScheduleData = DATA.data
        }
      })
    },

    // 添加 、编辑旅游行程
    metTourismProductSchedule (method, datas) {
      return new Promise(resolve => {
        api.metTourismProductSchedule(method, datas).then(res => {
          resolve(res.data)
        })
      })
    },

    // 详情 旅游行程
    getTourismProductScheduleShow (data) {
      return new Promise(resolve => {
        api.getTourismProductScheduleShow(data).then(res => {
          resolve(res.data.data)
        })
      })
    },

    // 状态变更 旅游行程
    ChangeTourismProductScheduleStatus (data) {
      return new Promise(resolve => {
        api.ChangeTourismProductScheduleStatus(data).then(res => {
          resolve(res)
        })
      })
    },

    // 旅游线路 获取日期价格
    getTourismProductSchedulePrice (data) {
      api.getTourismProductSchedulePrice(data).then(res => {
        const DATA = res.data
        if (DATA.code == 200) {
          DATA.data.forEach(item => {
            item.date = item.date * 1000
          })
          this.touristDatePrice = DATA.data
        }
      })
    },

    // 行程天数下的已有景点
    getScenicList () {
      return new Promise(resolve => {
        getScenicList().then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.optionsScenic = DATA.data.list
            resolve(DATA.data)
          } else {
            this.$message({
              type: 'error',
              message: '已有景点获取失败'
            })
          }
        })
      })
    },
    // 基础数据 所属分类
    getTouristType () {
      console.log(123)
      return new Promise(resolve => {
        getTouristType().then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.optionsTouristType = DATA.data
            resolve(DATA.data)
          } else {
            this.$message({
              type: 'error',
              message: '旅游分类获取失败'
            })
          }
        })
      })
    },
    // 基础数据 旅游区域
    getTouristArea () {
      return new Promise(resolve => {
        getTouristArea().then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.optionsDestination = DATA.data
            resolve(DATA.data)
          } else {
            this.$message({
              type: 'error',
              message: '目的地选项获取失败'
            })
          }
        })
      })
    },
    // 地址(省市两级)
    getAreaProvinceCity () {
      return new Promise(resolve => {
        getAreaProvinceCity().then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.optionsDeparture = DATA.data
            resolve(DATA.data)
          } else {
            this.$message({
              type: 'error',
              message: '出发地选项获取失败'
            })
          }
        })
      })
    },
    // 获取从CommonUploadList传来的图片删除数据
    handleRemove (data) {
      this.imgIndex.push(data)
    },
    // 添加行程天数
    clickAddTripData () {
      const goTo = this.$children[0].$el.querySelector('#fourthTab-From-p')
      this.$children[0].$el.scrollTop = goTo.offsetTop
      this.AddOrEditType = 'add'// 为 新增
    },
    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },
    // 切换Tab 储蓄列表、运营列表
    swichTable (tab) {
      const name = tab.name
      this.activeTableName = name
      // 刷新
      this.updataTableData()
    },
    // 切换tab Dialog新增、编辑
    swichTab (tab) {
      const name = tab.name
      this.activeTabName = name
    },
    // 刷新表格数据
    updataTableData () {
      if (this.activeTableName == 'first') {
        // 带审核
        this.getTourismProductToAudit()
      } else if (this.activeTableName == 'second') {
        // 正式运营
        this.getTourismProductToOperation()
      } else {
      }
    },
    // 添加数据
    async clickAddData () {
      const TouristType = await this.getTouristType() // 加载旅游分类
      const TouristArea = await this.getTouristArea() // 加载旅游区域
      const AreaProvinceCity = await this.getAreaProvinceCity()// 加载出发地选项
      const ScenicElements = await this.getScenicList()// 加载行程天数下的已有景点
      Promise.all([TouristType, TouristArea, AreaProvinceCity, ScenicElements]).then(res => {
        this.dialogFonfig.isShowDialogVisible = true
        this.dialogFonfig.openType = 'add'
      })
    },
    // 1.基本信息的保存
    async saveMainnformation (data) {
      const type = this.dialogFonfig.openType
      let result// 主信息上传是否成功
      if (type == 'add') {
        const DATA = {
          name: data.name,
          describe: data.describe,
          code_no: data.code_no,
          notice_mobile: data.notice_mobile,
          supplier_id: data.supplier_id,
          category_id: data.category_id,
          product_pic: data.product_pic,
          images: data.images,
          discount: data.discount,
          sales_price: data.sales_price,
          vip_price: data.vip_price,
          cost: data.cost,
          settlement_price: data.settlement_price,
          kid_sales_price: data.kid_sales_price,
          kid_vip_price: data.vip_price,
          kid_cost: data.kid_cost,
          kid_settlement_price: data.kid_settlement_price,
          is_hot: data.is_hot ? 1 : 0,
          is_red: data.is_red ? 1 : 0,
          is_coupon: data.is_coupon ? 1 : 0,
          is_integral: data.is_integral ? 1 : 0,
          is_stock: data.is_stock ? 1 : 0,
          is_kid: data.is_kid ? 1 : 0,
          vrurl: data.vrurl,
          day_num: data.day_num,
          travel_mode: data.travel_mode,
          departure: data.departure,
          destination: data.destination,
          transport: data.transport,
          group_mode: data.group_mode,
          month: data.month,
          station: data.station,
          sales_num: data.sales_num,
          sn: data.sn
        }
        result = await this.metTourismProductEdit('POST', DATA)
      } else if (type == 'edit') {
        const DATA = {
          id: data.id,
          name: data.name,
          describe: data.describe,
          code_no: data.code_no,
          notice_mobile: data.notice_mobile,
          supplier_id: data.supplier_id,
          category_id: data.category_id,
          product_pic: data.product_pic,
          images: data.images,
          discount: data.discount,
          sales_price: data.sales_price,
          vip_price: data.vip_price,
          cost: data.cost,
          settlement_price: data.settlement_price,
          kid_sales_price: data.kid_sales_price,
          kid_vip_price: data.vip_price,
          kid_cost: data.kid_cost,
          kid_settlement_price: data.kid_settlement_price,
          is_hot: data.is_hot ? 1 : 0,
          is_red: data.is_red ? 1 : 0,
          is_coupon: data.is_coupon ? 1 : 0,
          is_integral: data.is_integral ? 1 : 0,
          is_stock: data.is_stock ? 1 : 0,
          is_kid: data.is_kid ? 1 : 0,
          vrurl: data.vrurl,
          day_num: data.day_num,
          travel_mode: data.travel_mode,
          departure: data.departure,
          destination: data.destination,
          transport: data.transport,
          group_mode: data.group_mode,
          month: data.month,
          station: data.station,
          sales_num: data.sales_num,
          sn: data.sn
        }

        result = await this.metTourismProductEdit('PUT', DATA)
      }
      this.openFormTrip.product_id = result.data.id
      this.tabFormLabel[6].formTable = {
        discount: data.discount,
        sales_price: data.sales_price,
        vip_price: data.vip_price,
        cost: data.cost,
        settlement_price: data.settlement_price,
        kid_sales_price: data.kid_sales_price,
        kid_vip_price: data.vip_price,
        kid_cost: data.kid_cost,
        kid_settlement_price: data.kid_settlement_price
      }
      return result
    },
    // 2.日期价格保存
    async saveDatePrice (product_id) {
      const price_data = this.touristDatePrice
      let res_price
      await price_data.forEach(element => {
        element.date = element.date / 1000
      })

      let DATA_pricedate
      if (price_data.length == 0) {
        await this.$confirm('已保存基本信息，但检测日期价格为空, 是否保存空的日期价格?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          // 为日期价格空 的时候
          DATA_pricedate = {
            product_id: product_id,
            price_data: ''
          }
          res_price = await api.setTourismProductSchedulePrice(DATA_pricedate)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } else {
        // 时间戳 单位 改成 s(秒级别)
        DATA_pricedate = {
          product_id: product_id,
          price_data: price_data
        }

        res_price = await api.setTourismProductSchedulePrice(DATA_pricedate)
      }
      return res_price.data
    },
    // 4.推荐理由保存
    async saveRecommend (data) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // recommend	是	string	推荐理由

      const DATA_Recommend = {
        id: data.id,
        recommend: data.recommend
      }
      const res = await this.setTourismProductRecommend(DATA_Recommend)
      return res
    },
    // 5.费用说明保存
    async saveExpenseExplanation (data) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // expense_explanation	是	string	费用说明
      const DATA_expense_explanation = {
        id: data.id,
        expense_explanation: data.expense_explanation
      }
      const res = await this.setTourismProductExpense(DATA_expense_explanation)
      return res
    },
    // 6.产品特色保存
    async saveFeatureDescription (data) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // feature_description	是	string 产品特色
      const DATA_feature_description = {
        id: data.id,
        feature_description: data.feature_description
      }
      const res = await this.setTourismProductFeature(DATA_feature_description)
      return res
    },
    // 7.预定须知保存
    async saveBuyNotice (data) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // feature_description	是	string 预定须知
      const DATA_buy_notice = {
        id: data.id,
        buy_notice: data.buy_notice
      }
      const res = await this.setTourismProductBuyNotice(DATA_buy_notice)
      return res
    },
    // 确定提交数据操作
    async clickSubmitData () {
      const name = this.activeTabName// 当前Dialog Tab页面
      let res// 存放结果
      // 根据点击的页面，进行保存
      // 1.基本信息，2.推荐理由，3.产品特色，4.行程天数,5.费用说明，6.预定须知，7.日期价格
      const data = this.openForm
      switch (name) {
        case 'first':
          res = await this.saveMainnformation(data)
          console.log(res)
          this.openForm.id = this.openForm.id == '' ? res.data.id : this.openForm.id
          this.activeTabName = res.code == 200 ? 'second' : this.activeTabName
          break
        case 'second':
          console.log(data.id)

          res = await this.saveRecommend(data)
          this.activeTabName = res.code == 200 ? 'third' : this.activeTabName
          break
        case 'third':
          res = await this.saveFeatureDescription(data)
          this.activeTabName = res.code == 200 ? 'fourth' : this.activeTabName
          break
        case 'fourth':
          this.clickAddTripData()
          this.activeTabName = 'fourth' ? 'fifth' : this.activeTabName
          break
        case 'fifth':
          res = await this.saveExpenseExplanation(data)
          this.activeTabName = res.code == 200 ? 'sixth' : this.activeTabName
          break
        case 'sixth':
          res = await this.saveBuyNotice(data)
          this.activeTabName = res.code == 200 ? 'seventh' : this.activeTabName
          break
        case 'seventh':
          console.log(data.id)
          res = await this.saveDatePrice(data.id)
          if (res.code == 200) {
            this.touristDatePrice = []
            this.updataTableData()
            this.dialogFonfig.isShowDialogVisible = false
          }
          break
        default:
          break
      }
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: res.message
        })
      } else {
        this.$message({
          type: 'error',
          message: res.message
        })
      }
    },
    // 取消提交数据操作
    clickCancelData () {
      this.activeTabName = 'first'
      this.dialogFonfig.isShowDialogVisible = false
    },

    // 编辑操作
    async clickEditTourismData (row) {
      await this.getTouristType() // 加载旅游分类
      await this.getTouristArea() // 加载旅游区域
      await this.getAreaProvinceCity()// 加载出发地选项
      await this.getScenicList()// 加载行程天数下的已有景点
      const DATA = {
        id: row.id
      }
      const DATA_price = {
        product_id: row.id
      }
      // 获得详细线路信息
      const result = await this.getTourismProductShow(DATA)
      await this.getTourismProductSchedulePrice(DATA_price)
      await this.getTourismProductSchedule(DATA_price)
      const data = result.data
      this.imgIndex.forEach(item => {
        data.images.splice(this.imgIndex, 1)
      })
      this.imgIndex = []
      this.openForm = {
        id: data.id,
        name: data.name,
        describe: data.describe,
        code_no: data.code_no,
        notice_mobile: data.notice_mobile,
        supplier_id: data.supplier_id,
        category_id: data.category_id,
        product_pic: data.product_pic,
        images: data.images,
        discount: data.discount,
        sales_price: data.sales_price,
        vip_price: data.vip_price,
        cost: data.cost,
        settlement_price: data.settlement_price,
        kid_sales_price: data.kid_sales_price,
        kid_vip_price: data.vip_price,
        kid_cost: data.kid_cost,
        kid_settlement_price: data.kid_settlement_price,
        is_hot: data.is_hot == true ? 1 : 0,
        is_red: data.is_red == true ? 1 : 0,
        is_coupon: data.is_coupon == true ? 1 : 0,
        is_integral: data.is_integral == true ? 1 : 0,
        is_stock: data.is_stock == true ? 1 : 0,
        is_kid: data.is_kid == true ? 1 : 0,
        vrurl: data.vrurl,
        day_num: data.day_num,
        travel_mode: data.travel_mode,
        departure: data.departure,
        destination: data.destination,
        transport: data.transport,
        group_mode: data.group_mode,
        month: data.month,
        station: data.station,
        sales_num: data.sales_num,
        sn: data.sn,

        // 推荐理由
        recommend: data.recommend,
        // 费用说明
        expense_explanation: data.expense_explanation,
        // 产品特色
        feature_description: data.feature_description,
        // 订购须知
        buy_notice: data.buy_notice
      }
      this.openFormTrip.product_id = data.id
      this.tabFormLabel[6].formTable = {
        discount: data.discount,
        sales_price: data.sales_price,
        vip_price: data.vip_price,
        cost: data.cost,
        settlement_price: data.settlement_price,
        kid_sales_price: data.kid_sales_price,
        kid_vip_price: data.vip_price,
        kid_cost: data.kid_cost,
        kid_settlement_price: data.kid_settlement_price
      }
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    // 删除操作
    async clickDeleteTourismData (row) {
      this.$confirm('确定移除这条记录吗？', '提示', { type: 'error' }).then(() => {
        const DATA = {
          id: row.id
        }
        api.DeleteTourismProduct(DATA).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message.success('删除成功！')
          }
          this.updataTableData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 表格开关操作
    async changeTourismSwitch (row) {
      // id		线路ID
      // status	int	状态 1上架 3下架
      const DATA = {
        id: row.id,
        status: row.status == 0 ? 3 : 1
      }

      const res = await this.ChangeTourismProductStatus(DATA)
      if (res.data.code == 200) {
        this.$message({
          type: 'success',
          message: DATA.status == 3 ? '下架成功' : '上架成功'
        })
      }
    },
    dateBetweenDeal (startDate, endDate) {
      // 计算日期差处理， 返回时间戳

      let st = new Date(startDate).getTime()
      const et = new Date(endDate).getTime()
      const retArr = []
      const oneDay = 1000 * 60 * 60 * 24

      while (st != et) {
        retArr.push(st)
        st = st + oneDay
      }
      retArr.push(et)
      return retArr
    },
    // 筛选按钮 点击事件
    getScreen () {
      this.modifyDate()
    },
    // 获取复选框内对象的值并处理
    getChecked (data) {
      var arr = data.shift()
      this.getchecked.push(arr)
      for (var i = 0; i < this.getchecked.length; i++) {
        if (this.getchecked[i].dateWhether == false) {
          var aa = this.getchecked[i].dateId
          var rr = 0
          this.getchecked.splice(i, 1)
          for (var j = 0; j < this.getchecked.length; j++) {
            if (this.getchecked[j].dateId == aa) {
              rr = j
              break
            }
          }
          this.getchecked.splice(rr, 1)
        }
      }
    },
    // 按照复选框的选项修改日期
    modifyDate () {
      // -----------------第一步:先把上面日期范围与下面表格中相对应的日期提取出来--------//
      // 根据上面的日期范围筛选出下面的日期进行更改
      const arr = []// 暂时存放旅游数据中日期价格下的日期信息
      const ARR = []// 存放经过筛选之后的日期信息
      const touristDateObj = []
      // console.log(this.touristDatePrice)
      this.touristDatePrice.forEach(item => {
        arr.push(item.date)
      })
      // console.log(this.dateArray[0])
      // console.log(this.dateArray[this.dateArray.length - 1])
      // console.log(arr)
      var a = arr.filter(item => {
        return item <= this.dateArray[this.dateArray.length - 1]
      })
      var b = arr.filter(item => {
        return this.dateArray[0] <= item
      })
      a.forEach(item => {
        b.forEach(item2 => {
          if (item == item2) {
            ARR.push(item2)
          }
        })
      })
      // console.log(ARR)
      this.touristDatePrice.forEach(item => {
        ARR.forEach(item2 => {
          if (item.date == item2) {
            touristDateObj.push(item)
          }
        })
      })
      console.log(touristDateObj)
      console.log(touristDateObj.length)

      // -----------------第二步:根据上面提取出来的日期数据,获取对应星期几的下标--------//
      const gainChecked = []// 哪一个复选框的id
      const week2 = []// 存放日期
      const arr2 = []
      // console.log(this.tabFormLabel[6].formTable)
      for (var i = 0; i < this.getchecked.length; i++) {
        gainChecked.push(this.getchecked[i].dateId)
      }
      const StorageTimestamp = []
      touristDateObj.forEach((item, index) => {
        const obj = item
        StorageTimestamp.push(obj.date)
      })
      // console.log(StorageTimestamp)
      for (let i = 0; i < StorageTimestamp.length; i++) {
        const week = new Date(StorageTimestamp[i]).getDay()
        week2.push(week)
      }
      // 通过gainChecked的id值来筛选符合week2里id，获取下标
      week2.forEach((item, index) => {
        gainChecked.forEach(item2 => {
          if (item == item2) {
            arr2.push(index)
          }
        })
      })
      // console.log(StorageTimestamp)
      // console.log(week2)
      console.log(arr2)

      // ---第三步:根据星期几的下标,修改对应的数据，再把数据取出来对到this.touristDatePrice上---//
      const screenData = []
      this.touristDatePrice.forEach((item, index) => {
        touristDateObj.forEach((item2, index2) => {
          if (item.date == item2.date) {
            screenData.push(item)
          }
        })
      })
      for (let i = 0; i < arr2.length; i++) {
        // console.log(arr2[i])
        screenData[arr2[i]].discount = this.tabFormLabel[6].formTable.discount
        screenData[arr2[i]].sales_price = this.tabFormLabel[6].formTable.sales_price
        screenData[arr2[i]].vip_price = this.tabFormLabel[6].formTable.vip_price
        screenData[arr2[i]].cost = this.tabFormLabel[6].formTable.cost
        screenData[arr2[i]].settlement_price = this.tabFormLabel[6].formTable.settlement_price
        screenData[arr2[i]].kid_sales_price = this.tabFormLabel[6].formTable.kid_sales_price
        screenData[arr2[i]].kid_vip_price = this.tabFormLabel[6].formTable.kid_vip_price
        screenData[arr2[i]].kid_cost = this.tabFormLabel[6].formTable.kid_cost
        screenData[arr2[i]].kid_settlement_price = this.tabFormLabel[6].formTable.kid_settlement_price
        screenData[arr2[i]].integral_price = this.tabFormLabel[6].formTable.integral_price
        screenData[arr2[i]].stock_num = this.tabFormLabel[6].formTable.stock_num
      }
      this.touristDatePrice.forEach((item, index) => {
        screenData.forEach((item2, index2) => {
          if (item.date == item2.date) {
            item = item2
          }
        })
      })
      console.log(this.touristDatePrice)
    },
    // 获取日期价格中日期信息
    blurDate (date) {
      // console.log(date)
      // 处理信息
      const startDate = date[0] // 开始时间
      const endDate = date[1] // 结束时间
      this.dateArray = this.dateBetweenDeal(startDate, endDate)
      // console.log(this.dateArray)
    },
    // 根据返回的时间戳，把数据展示到表格上
    calculateDate () {
      const result = this.tabFormLabel[6].formTable
      const startDate = result.date[0] // 开始时间
      const endDate = result.date[1] // 结束时间
      if (!startDate && !endDate) {
        this.$message('选择日期')
        return
      }
      const dateArray = this.dateBetweenDeal(startDate, endDate)
      const existArr = this.touristDatePrice.map((item) => {
        return item.date
      })
      // 判断是否已存在日期价格
      const newArr = []
      const newArr2 = []
      // console.log(existArr)
      dateArray.forEach((item) => {
        if (!existArr.includes(item)) {
          newArr.push(item)
        } else {
          newArr2.push(item)
          this.resultArr = newArr2
        }
      })
      if (this.resultArr) {
        // console.log(222)
      } else {
      }
      this.formdIndex++
      const priceArray = newArr.map(item2 => {
        return {
          id: result.id,
          date: item2,
          discount: result.discount,
          sales_price: result.sales_price,
          vip_price: result.vip_price,
          cost: result.cost,
          settlement_price: result.settlement_price,
          kid_sales_price: result.kid_sales_price,
          kid_vip_price: result.kid_vip_price,
          kid_cost: result.kid_cost,
          kid_settlement_price: result.kid_settlement_price,
          integral_price: result.integral_price,
          stock_num: result.stock_num
        }
      })
      this.touristDatePrice = this.touristDatePrice.concat(priceArray)
      console.log(this.touristDatePrice)
    },
    // 删除 日期价格
    clickDeleteDatePrice (row) {
      const index = this.touristDatePrice.some((item, index) => {
        if (item.date == row.date) {
          this.touristDatePrice.splice(index, 1)
          return true
        }
      })
    },
    // 添加 景点数
    async clickAddScenic () {
      const DATA = {
        name: '',
        explain: '',
        img: [],
        note: '',
        logo_type: 1 // 图标 待完善
      }
      this.openFormTrip.scenic_spots = this.openFormTrip.scenic_spots.concat(DATA)
    },
    // 删除 景点卡片
    clickDeleteCard (row) {
      this.openFormTrip.scenic_spots.some((item, index) => {
        if (item.name == row.name) {
          this.openFormTrip.scenic_spots.splice(index, 1)
          return true
        }
      })
    },
    // 审核操作
    clickAuditTourismData (row) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // status	是	int	状态 3、通过 2、驳回
      // reason	是	string	审核理由
      this.auditForm = {
        id: row.id,
        name: row.name,
        product_pic: row.product_pic,
        status: row.status
      }
      this.dialogFonfigAudit.isShowDialogVisible = true
    },
    // 审核结果提交
    async clickSubmitAuditData (statue, data) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // status	是	int	状态 3、通过 2、驳回
      const DATA = {
        id: data.id,
        status: statue,
        reason: data.reason
      }
      await api.getTourismProductToCheck(DATA).then(async (res) => {
        const DATA = res.data
        if (DATA.code == 200) {
          this.$message({
            type: 'success',
            message: DATA.message
          })
          await this.updataTableData()
        } else {
          this.$message({
            type: 'error',
            message: DATA.message
          })
        }
      })// 调用审核接口

      this.dialogFonfigAudit.isShowDialogVisible = false
    },
    // 取消提交数据操作
    clickCancelData () {
      this.activeTabName = 'first'
      this.dialogFonfig.isShowDialogVisible = false
      this.dialogFonfigAudit.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.auditName = this.$options.data().auditName
      this.openForm = this.$options.data().openForm
      this.clearFromData()
    },
    // 行程信息添加保存
    async clickSavaScheduleData (data, type) {
      let res// 存放结果
      const E_value = this.tabFormLabel[3].formLabel[10].formLabel[0].options
      // 处理景点名字
      data.scenic_spots.forEach(item => {
        if (item.Existingname == undefined || item.Existingname.length == 0) {
          item.name = item.Existingname1
        } else {
          let num = 0
          item.Existingname.forEach(item2 => {
            E_value.forEach(item3 => {
              if (item3.value == item2) {
                num++
                item.Existingname.push(item3.label)
              }
            })
          })
          item.Existingname.splice(0, num)
          num = 0
          item.name = item.Existingname.join(',')
        }
      })
      console.log(data.scenic_spots)
      if (type == 'add') {
        const DATA = {
          // 旅游行程信息
          product_id: data.product_id, // 线路id
          title: data.title,
          outline: data.outline,
          day: data.day,
          meal: data.meal,
          meal_explain: data.meal_explain,
          meal_img: data.meal_img,
          hotel_id: data.hotel_id, // 关联酒店ID 组件待完善
          dwell: data.dwell,
          dwell_explain: data.dwell_explain,
          dwell_img: data.dwell_img,
          scenic_spots: data.scenic_spots,
          attraction_id: data.attraction_id, // 关联景点元素ID 组件待完善
          note: data.note
        }
        res = await this.metTourismProductSchedule('POST', DATA)
        this.openFormTrip.id = res.data.id
      }
      if (type == 'edit') {
        const DATA = {
          // 旅游行程信息
          product_id: data.product_id, // 线路id
          id: data.id, // 日程id
          title: data.title,
          outline: data.outline,
          day: data.day,
          meal: data.meal,
          meal_explain: data.meal_explain,
          meal_img: data.meal_img,
          hotel_id: data.hotel_id, // 关联酒店ID 组件待完善
          dwell: data.dwell,
          dwell_explain: data.dwell_explain,
          dwell_img: data.dwell_img,
          scenic_spots: data.scenic_spots,
          attraction_id: data.attraction_id, // 关联景点元素ID 组件待完善
          note: data.note
        }
        // DATA.scenic_spots.forEach(item => {
        //   delete item.Existingname
        // })
        console.log(DATA)
        res = await this.metTourismProductSchedule('PUT', DATA)
      }
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: res.message
        })
        await this.updataScheduleData(this.openForm.id)
        this.AddOrEditType = 'add'
        // 保存完毕后，自动跳到表格 锚点
        const goTop = this.$children[0].$el.querySelector('#fourthTab-button')
        this.$children[0].$el.scrollTop = goTop.offsetTop
      } else {
        this.$message({
          type: 'error',
          message: res.message
        })
      }
    },
    // 行程 启/禁用
    async changeScheduleSwitch (row) {
      // id		行程ID
      // status	int	状态 1启用 0禁用
      console.log(row)
      const DATA = {
        id: row.id,
        status: row.status == 0 ? 0 : 1
      }

      const res = await this.ChangeTourismProductScheduleStatus(DATA)
      if (res.data.code == 200) {
        this.$message({
          type: 'success',
          message: DATA.status == 0 ? '禁用成功' : '启用成功'
        })
      }
    },

    // 刷新日程管理 表单
    async updataScheduleData (id) {
      await this.getTourismProductSchedule({
        product_id: id
      })
      this.openFormTrip = this.$options.data().openFormTrip
      this.openFormTrip.product_id = this.openForm.id
    },

    // 重置日程管理 表单
    clickResetScheduleFrom () {
      this.openFormTrip = this.$options.data().openFormTrip
      this.openFormTrip.product_id = this.openForm.id
    },

    // 日程管理 表格  编辑操作
    async clickEditScheduleData (row) {
      const E_value = this.tabFormLabel[3].formLabel[10].formLabel[0].options
      const goTo = this.$children[0].$el.querySelector('#fourthTab-From-p')
      this.$children[0].$el.scrollTop = goTo.offsetTop
      this.AddOrEditType = 'edit'
      const DATA = {
        id: row.id
      }
      const data = await this.getTourismProductScheduleShow(DATA)
      let num = 0
      // 处理景点名字
      data.scenic_spots.forEach(item => {
        if (item.Existingname == undefined || item.Existingname.length == 0) {
          this.openFormTrip.Existingname1 = item.name
        } else {
          item.Existingname.forEach(item2 => {
            E_value.forEach(item3 => {
              if (item2 == item3.label) {
                num++
                item.Existingname.push(item3.value)
              }
            })
          })
          item.Existingname.splice(0, num)
          num = 0
          this.openFormTrip.Existingname = item.Existingname
          console.log(item)
        }
      })
      this.openFormTrip = {
        id: data.id, // 行程id
        product_id: data.product_id, // 线路id
        title: data.title,
        outline: data.outline,
        day: data.day,
        meal: data.meal,
        meal_explain: data.meal_explain,
        meal_img: data.meal_img,
        hotel_id: data.hotel_id, // 关联酒店ID 组件待完善
        dwell: data.dwell,
        dwell_explain: data.dwell_explain,
        dwell_img: data.dwell_img,
        scenic_spots: data.scenic_spots,
        attraction_id: data.attraction_id, // 关联景点元素ID 组件待完善
        note: data.note
      }
    },
    // 日程管理 表格  删除操作
    async clickDeleteScheduleData (row) {
      this.$confirm('确定移除这条记录吗？', '提示', { type: 'error' }).then(() => {
        const DATA = {
          id: row.id
        }
        api.DeleteTourismProductSchedule(DATA).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.updataScheduleData(this.openForm.id)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 清空表单
    clearFromData () {
      this.openForm = this.$options.data().openForm
      this.openFormTrip = this.$options.data().openFormTrip
      this.tabFormLabel[6].formTable = this.$options.data().tabFormLabel[6].formTable
      this.touristDatePrice = []
      this.touristScheduleData = []
    }
  }

}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .manage-content {
    /deep/ .el-tabs__content {
      // height: 806px;
    }
  }
  .filtrateInput {
    background: #f8f7f7;
    padding: 12px 8px;
    padding-right: 45%;
    margin: 8px 0px;
  }
  .fourthTab {
    height: 100%;
  }
  .box {
    width: 700px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .box-card {
      margin-right: 10px;
      margin-bottom: 10px;
      width: 420px;
      height: auto;
      position: relative;
    }
  }
  .dialogAudit {
    /deep/ .el-dialog__body {
      padding-right: 45%;
    }
  }
}
</style>
